<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/p361_logo1.png" style="width:350px"/>
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="rn-page-title text-center pt--100">
              <h2 class="heading-title theme-gradient">Pre-Register for the Beta</h2>
              <p>
                Early access to our mobile and web apps.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

        <!-- Start List Style  -->
    <div class="rn-list-style rn-section-gap bg_color--1">
      <v-container>
              <h2>Some features to look forward to.</h2>
        <v-row>
          <!-- Start Single List Style  -->
          <v-col
            lg="4"
            md="4"
            sm="6"
            cols="12"
            v-for="(singleList, i) in hoaListContent"
            :key="i"
            :class="singleList.class"
          >
            <div class="list-style-inner">
              <h3 class="fontWeight500">{{ singleList.title }}</h3>
              <p>
                {{ singleList.desc }}
              </p>
              <ul class="list-style--1">
                <li v-for="list in singleList.listContent" :key="list.id">
                  <i v-html="iconSvg(list.icon)"></i>
                  {{ list.desc }}
                </li>
              </ul>
            </div>
          </v-col>
          <!-- End Single List Style  -->
        </v-row>
      </v-container>
    </div>
    <!-- End List Style  -->

    <!-- Start Contact Area  -->
    <div class="rn-contact-area rn-section-gap bg_color--1">
      <div class="contact-form--1">
        <v-container>
          <Contact>
            <img
              slot="contact-img"
              src="../assets/images/about/contactusbottom.jpg"
              alt="property361"
            />
          </Contact>
        </v-container>
      </div>
    </div>
    <!-- End Contact Area  -->

    <Footer />
  </div>
</template>

<script>
  import hoaListContent from "../data/hoaListContent.json"
  import Header from "../components/header/Header";
  import ContactAddress from "../components/contact/ContactAddress";
  import Contact from "../components/contact/PromoRegister";
  import Brand from "../components/brand/Brand";
  import Footer from "../components/footer/Footer";
  import feather from "feather-icons";
  export default {
    components: { Header, ContactAddress, Contact, Brand, Footer },
        data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "List Style",
            to: "",
            disabled: true,
          },
        ],
        hoaListContent
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<style></style>
