<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
        <h2 class="heading-title">Pre-Register for the Beta.</h2>
        <p class="description">
          We will send confirmation once we open access to the Web and Mobile applications.
        </p>
      </div>
      <div id="hubspotForm" v-once></div>
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
      <div class="thumbnail mb_md--40 mb_sm--40">
        <img src="../../assets/images/about/contactusbottom.jpg" alt="property361" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import axios from "axios";
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        formData: {
          name: "",
          email: "",
          subject: "Pre-Register for Beta",
          message: "I'm interested in the beta..",
        },
      };
    },
    mounted() {
      const script = document.createElement("script");
      script.src="https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "23158342",
            formId: "3fbe49a1-6fc7-4039-860a-2b2b52f2c711",
            target: "#hubspotForm"
          })
        }
      })
    }
  };
</script>
